<template>
  <PageWithLayout :isBodyBg="false">
    <div class="group_box">
      <!-- Button -->
      <BoxView title="Button" :grid="100">
        <TableView>
          <template v-slot:tbl_colgroup>
            <col/>
            <col/>
            <col/>
            <col/>
            <col/>
            <col/>
          </template>
          <template v-slot:tbl_head>
            <th class="th_divide">
              <span class="txt_divide">size</span>
              <span class="txt_divide">style</span>
            </th>
            <th>xsmall</th>
            <th>small</th>
            <th>medium</th>
            <th>large</th>
            <th>disabled</th>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>secondary</th>
              <td>
                <Button
                  btnSize="xsmall"
                  btnStyle="secondary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="small"
                  btnStyle="secondary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="medium"
                  btnStyle="secondary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="secondary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="secondary"
                  text="button"
                  :disabled="true"/>
              </td>
            </tr>
            <tr>
              <th>secondary_border</th>
              <td>
                <Button
                  btnSize="xsmall"
                  btnStyle="secondary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="small"
                  btnStyle="secondary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="medium"
                  btnStyle="secondary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="secondary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="secondary_border"
                  text="button"
                  :disabled="true"/>
              </td>
            </tr>
            <tr>
              <th>tertiary</th>
              <td>
                <Button
                  btnSize="xsmall"
                  btnStyle="tertiary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="small"
                  btnStyle="tertiary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="medium"
                  btnStyle="tertiary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="tertiary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="tertiary"
                  text="button"
                  :disabled="true"/>
              </td>
            </tr>
            <tr>
              <th>tertiary_border</th>
              <td>
                <Button
                  btnSize="xsmall"
                  btnStyle="tertiary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="small"
                  btnStyle="tertiary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="medium"
                  btnStyle="tertiary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="tertiary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="tertiary_border"
                  text="button"
                  :disabled="true"/>
              </td>
            </tr>
            <tr>
              <th>fourthly</th>
              <td>
                <Button
                  btnSize="xsmall"
                  btnStyle="fourthly"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="small"
                  btnStyle="fourthly"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="medium"
                  btnStyle="fourthly"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="fourthly"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="fourthly"
                  text="button"
                  :disabled="true"/>
              </td>
            </tr>
            <tr>
              <th>fourthly_border</th>
              <td>
                <Button
                  btnSize="xsmall"
                  btnStyle="fourthly_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="small"
                  btnStyle="fourthly_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="medium"
                  btnStyle="fourthly_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="fourthly_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="fourthly_border"
                  text="button"
                  :disabled="true"/>
              </td>
            </tr>
            <tr>
              <th>primary</th>
              <td>
                <Button
                  btnSize="xsmall"
                  btnStyle="primary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="small"
                  btnStyle="primary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="medium"
                  btnStyle="primary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="primary"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="primary"
                  text="button"
                  :disabled="true"/>
              </td>
            </tr>
            <tr>
              <th>primary_border</th>
              <td>
                <Button
                  btnSize="xsmall"
                  btnStyle="primary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="small"
                  btnStyle="primary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="medium"
                  btnStyle="primary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="primary_border"
                  text="button"/>
              </td>
              <td>
                <Button
                  btnSize="large"
                  btnStyle="primary_border"
                  text="button"
                  :disabled="true"/>
              </td>
            </tr>
          </template>
        </TableView>
      </BoxView>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

export default {
  name:'Buttons',
  components:{
    PageWithLayout,
    BoxView,
    TableView,
    Button,
  },
}
</script>

<style scoped>
</style>